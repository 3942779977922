import { lazy } from "react";

import MainLayout from "../layouts/MainLayout";
import { ProtectedRoutes } from "./ProtectedRoutes";

const Landing = lazy(() => import("../views/Landing"));
const Stocks = lazy(() => import("../views/Stocks"));
const NotFound = lazy(() => import("../views/NotFound"));

export const MainRoutes = {
    element: <MainLayout />,
    children: [
        {
            element: <ProtectedRoutes />,
            children: [
                // Route pour la page d'accueil
                {
                    path: "/",
                    element: <Landing />,
                },
                // Route pour la page de gestion des stocks
                {
                    path: "stock",
                    element: <Stocks />,
                },
            ],
        },
        {
            path: "*",
            element: <NotFound />,
        },
    ],
};
