import React from "react";
import { Link } from "react-router-dom";
import { ExpressIcon } from "./icons/ExpressIcon";
import { ReactIcon } from "./icons/ReactIcon";

export default function Footer() {
    return (
        <footer className="text-white py-2 w-full bg-primary">
            <div className="w-full flex justify-center items-center">
                <p className="flex justify-center items-center w-full">
                    Développé avec
                    <ReactIcon
                        width={24}
                        height={24}
                        color={"white"}
                        className="px-1 animate-spin-slow"
                    />
                    &
                    <ExpressIcon
                        width={24}
                        height={24}
                        color={"white"}
                        className="px-1"
                    />
                    par
                    <Link
                        className="underline pl-1"
                        to="https://www.roulemarcel.fr/"
                        target="_blank"
                    >
                        RouleMarcel
                    </Link>
                </p>
            </div>
        </footer>
    );
}
