import { CircleUserRound, LogIn, LogOut } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import packages from "../../package.json";
import { logout } from "../api/Authentication";
import userStore from "../contexts/UserStore";
import { UserPaths } from "../routes/UserRoutes";

export default function Header() {
    const user = userStore((state) => state.getUser);
    const clearUser = userStore((state) => state.clearUser);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isAccount, setIsAccount] = useState(false);
    const menuButtonRef = useRef(null);

    const navigate = useNavigate();

    // Fonction pour se déconnecter
    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            const data = await logout();
            if (data) {
                clearUser();
                navigate("/auth/connexion");
                setIsMenuOpen(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuButtonRef.current &&
                !menuButtonRef.current.contains(event.target)
            ) {
                setIsAccount(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <header className="flex items-center py-2 bg-base-200 mx-auto w-full px-5 md:px-20 lg:px-40 xxl:px-60 relative">
                <Link
                    to="/"
                    className="flex cursor-pointer hover:text-red-600"
                    onClick={() => setIsMenuOpen(false)}
                >
                    <h1 className="text-center text-xl font-bold py-4">
                        Stock & Go
                    </h1>
                </Link>
                <div className="flex-1" />

                {/*  Menu Mobile */}
                {user() ? (
                    <div className="flex gap-2 md:hidden items-center">
                        <label class="hamburger">
                            <input
                                type="checkbox"
                                checked={isMenuOpen}
                                onClick={() => setIsMenuOpen(!isMenuOpen)}
                                aria-label="Toggle menu"
                            />
                            <svg viewBox="0 0 32 32" className="w-8 h-8">
                                <path
                                    class="line line-top-bottom"
                                    d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
                                ></path>
                                <path class="line" d="M7 16 27 16"></path>
                            </svg>
                        </label>
                        <Link
                            to={UserPaths.profil}
                            className="flex text-center uppercase"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            <CircleUserRound className="text-white" />
                        </Link>
                        <LogOut
                            onClick={(e) => handleLogout(e)}
                            className="flex bg-primary text-white p-2 rounded-lg justify-center items-center w-9 h-9"
                        />
                    </div>
                ) : (
                    <Link to="/auth/connexion" className="md:hidden">
                        <LogIn className="flex bg-primary text-white p-2 rounded-lg justify-center items-center w-9 h-9" />
                    </Link>
                )}

                {isMenuOpen && (
                    <div className="absolute top-full bg-base-200 z-50 w-full left-0 md:hidden py-4 min-h-[calc(100vh-76px)] flex items-center">
                        {user() && (
                            <div className="flex justify-center w-full overflow-auto">
                                <div className="flex flex-col items-center gap-8">
                                    <div className="w-full h-[2px] bg-red-600" />
                                    <Link
                                        to={UserPaths.machines}
                                        className="flex text-center uppercase"
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        Mes bornes
                                    </Link>
                                    <div className="w-full h-[2px] bg-red-600" />
                                    <Link
                                        to={UserPaths.products}
                                        className="flex text-center uppercase"
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        Mes produits
                                    </Link>
                                    <div className="w-full h-[2px] bg-red-600" />
                                    <Link
                                        to={UserPaths.tours}
                                        className="flex text-center uppercase"
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        Mes tournées
                                    </Link>
                                    <div className="w-full h-[2px] bg-red-600" />
                                    <Link
                                        to={UserPaths.profiles}
                                        className="flex text-center uppercase"
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        Mes profils
                                    </Link>
                                    <div className="w-full h-[2px] bg-red-600" />
                                    <Link
                                        to={UserPaths.settings}
                                        className="flex text-center uppercase"
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        Mes informations
                                    </Link>
                                    <div className="w-full h-[2px] bg-red-600" />
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {/*  Menu Desktop */}
                {!user() ? (
                    <div className="md:flex gap-4 hidden">
                        <Link
                            to="/auth/connexion"
                            className="flex bg-primary text-white p-2 rounded-lg hover:bg-primary/60"
                        >
                            Se connecter
                        </Link>
                    </div>
                ) : (
                    <div className="md:flex gap-2 justify-center items-center hidden relative">
                        <p
                            className="flex text-center hover:text-red-600 cursor-pointer"
                            onClick={() => setIsAccount(!isAccount)}
                            ref={menuButtonRef}
                        >
                            Menu
                        </p>
                        <div className="w-[1px] h-[20px] bg-red-600" />
                        <Link
                            to="/mon-compte"
                            className="flex text-center hover:text-red-600"
                        >
                            <CircleUserRound />
                        </Link>
                        <div className="w-[1px] h-[20px] bg-red-600" />
                        <button
                            className="flex bg-primary text-white p-2 rounded-lg hover:bg-primary/60"
                            onClick={(e) => handleLogout(e)}
                        >
                            Se déconnecter
                        </button>

                        <div
                            className={`left-0 absolute top-[58px] bg-base-200 p-4 shadow-lg transition-all duration-300 flex flex-col gap-2 uppercase items-center z-50 ${
                                isAccount
                                    ? "opacity-100 translate-x-0"
                                    : "opacity-0 translate-x-40 pointer-events-none"
                            }`}
                        >
                            <div className="w-full h-[1px] bg-red-600" />
                            <Link
                                to={UserPaths.machines}
                                className="flex text-center hover:text-red-600"
                                onClick={() => setIsAccount(false)}
                                onMouseDown={(e) => e.stopPropagation()}
                            >
                                Mes bornes
                            </Link>
                            <div className="w-full h-[1px] bg-red-600" />
                            <Link
                                to={UserPaths.products}
                                className="flex text-center hover:text-red-600"
                                onClick={() => setIsAccount(false)}
                                onMouseDown={(e) => e.stopPropagation()}
                            >
                                Mes produits
                            </Link>
                            <div className="w-full h-[1px] bg-red-600" />
                            <Link
                                to={UserPaths.tours}
                                className="flex text-center hover:text-red-600"
                                onClick={() => setIsAccount(false)}
                                onMouseDown={(e) => e.stopPropagation()}
                            >
                                Mes tournées
                            </Link>
                            <div className="w-full h-[1px] bg-red-600" />
                            <Link
                                to={UserPaths.profiles}
                                className="flex text-center hover:text-red-600"
                                onClick={() => setIsAccount(false)}
                                onMouseDown={(e) => e.stopPropagation()}
                            >
                                Mes profils
                            </Link>
                            <div className="w-full h-[1px] bg-red-600" />
                            <Link
                                to={UserPaths.settings}
                                className="flex text-center hover:text-red-600"
                                onClick={() => setIsAccount(false)}
                                onMouseDown={(e) => e.stopPropagation()}
                            >
                                Mes informations
                            </Link>
                            <div className="w-full h-[1px] bg-red-600" />
                        </div>
                    </div>
                )}
                <div className="badge badge-xs fixed top-1 right-1">
                    v{packages.version}
                </div>
            </header>
        </>
    );
}
