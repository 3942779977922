import { lazy } from "react";

import AuthLayout from "../layouts/AuthLayout";

const Login = lazy(() => import("../views/auth/Login"));
// const Register = lazy(() => import("../views/auth/Register"));

export const AuthenticationRoutes = {
    path: "/auth",
    element: <AuthLayout />,
    children: [
        // Route pour la page de connexion
        {
            path: "connexion",
            element: <Login />,
        },
        // Route pour la page d'inscription
        // {
        //     path: "inscription",
        //     element: <Register />,
        // },
    ],
};
